<template>
  <v-container fluid class="grey lighten-5 top">
    <v-container fluid class="grey lighten-5">
      <v-card>
        <v-card-text>
          <v-form ref="registerForm" v-model="valid" lazy-validation>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="firstName"
                  :rules="[rules.required]"
                  label="First Name"
                  maxlength="20"
                  required
                />
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="lastName"
                  :rules="[rules.required]"
                  label="Last Name"
                  maxlength="20"
                  required
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="email"
                  :rules="emailRules"
                  label="E-mail"
                  required
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="password"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required, rules.min]"
                  :type="show1 ? 'text' : 'password'"
                  name="input-10-1"
                  label="Password"
                  hint="At least 6 characters"
                  counter
                  @click:append="show1 = !show1"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="verify"
                  block
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required, passwordMatch]"
                  :type="show1 ? 'text' : 'password'"
                  name="input-10-1"
                  label="Confirm Password"
                  counter
                  @click:append="show1 = !show1"
                />
              </v-col>
              <v-spacer />
              <v-col class="d-flex ml-auto xsm" cols="12" sm="3">
                <v-btn
                  x-large
                  block
                  :disabled="!valid"
                  color="success"
                  @click="onSubmit"
                >
                  Register
                </v-btn>
              </v-col>
              <v-col class="d-flex ml-auto xsm" cols="12" sm="3">
                <v-btn
                  x-large
                  block
                  :disabled="!valid"
                  color="success"
                  @click="validate"
                >
                  validate
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-container>
    <dialog-msg />
  </v-container>
</template>
<script>
import EventBus from "./EventBus";
import DialogMsg from "./dialogMessage.vue";
import { mapActions, mapGetters } from "vuex"; // mapGetters를 추가한다

const register = "register";

export default {
  name: "RegisterForm",
  components: {
    DialogMsg,
  },
  data() {
    return {
      uid: "",
      valid: true,
      dialog: false,
      dialogHead: "",
      dialogMessage: "",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      verify: "",
      loginPassword: "",
      loginEmail: "",
      loginEmailRules: [
        (v) => !!v || "Required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      emailRules: [
        (v) => !!v || "Required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],

      show1: false,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => (v && v.length >= 6) || "Min 6 characters",
      },
    };
  },
  computed: {
    passwordMatch() {
      return () => this.password === this.verify || "Password must match";
    },
    ...mapGetters({
      errorState: "getErrorState", // getter로 errorState를 받는다
    }),
  },
  methods: {
    clickBtn() {
      EventBus.$emit("push-msg", this.userObject);
    },
    async validate() {
      // if (this.$refs.loginForm.validate()) {
      //   //
      // }
      // encode

      let userObject = {
        Head: this.dialogHead,
        Message: this.dialogMessage,
      };
      this.$store.dispatch("eventMsg/AC_DIALOG_MSG", userObject);
      EventBus.$emit("push-msg", userObject);
      // const createHashedPassword = await this.createHashedPassword();
      // console.log("createHashedPassword", createHashedPassword);
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    ...mapActions(register, ["Register", "createHashedPassword"]),
    async onSubmit() {
      try {
        console.log(this.createHashedPassword(this.password));
        console.log(this.email);
        const use_password = await this.createHashedPassword(this.password);
        const loginResult = await this.Register({
          fun_code: "Register",
          use_id: this.email,
          use_password: use_password,
          use_first_name: this.firstName,
          use_last_name: this.lastName,
          use_yn: "0",
          use_init_yn: "0",
        });
        console.log("loginResult", loginResult);
        if (loginResult.data.repCode === "00") {
          this.goToPages(); // 페이지 이동!
        } else {
          console.log("loginResult", loginResult.data.repCode);
          this.dialog = true;
          this.dialogMessage = loginResult.data.repMessage;
        }
      } catch (err) {
        console.error(err);
      }
    },
    goToPages() {
      this.$router.push({
        name: "Login",
      });
    },
  },
};
</script>
<style scoped>
.alert-danger p {
  color: red;
}
</style>
